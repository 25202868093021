/* eslint-disable no-process-env */
import { AdminTRPCProvider } from '@newfront-insurance/admin-ui';
import { LogoSpinner } from '@newfront-insurance/core-ui';
import { SSRBoundary } from '@newfront-insurance/next-auth';
import { NotificationProvider } from '@newfront-insurance/next-notifications';
import { RouterProvider } from '@newfront-insurance/next-router-provider';
import { createContainer } from '@newfront-insurance/react-provision';
import React, { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

import { AnalyticsProvider } from './analytics';
import { FeatureFlagProvider } from './feature-flag';

import { AuthProvider, AuthSwapProvider } from '@/client/providers/auth';
import { ResponsiveProvider } from '@/client/providers/responsive';
import { SentryUserProvider } from '@/client/providers/sentry';
import { AuthSwrProvider } from '@/client/providers/swr';
import { SwapTRPCProvider, RootTRPCProvider, TRPCProvider, SwapTRPCClientProvider } from '@/client/providers/trpc';

export const queryClient = new QueryClient();

interface Props {
  children: React.ReactNode | React.ReactNode[];
}

/**
 * These are the common service providers used everywhere that don't require user state.
 */
const BasicProviders = createContainer([
  RouterProvider,
  ResponsiveProvider,
  NotificationProvider,
  ({ children }) => <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>,
]);

/**
 * These are the providers that provide information about the current user.
 */
const IdentityProviders = createContainer([AuthProvider, AuthSwapProvider]);

/**
 * These providers give us access to TRPC clients and depend on the identity providers.
 */
const TRPCProviders = createContainer([RootTRPCProvider, SwapTRPCClientProvider, SwapTRPCProvider, TRPCProvider]);

/**
 * These providers give us access to team UI libraries.
 */
const TeamLibraryProviders = createContainer([
  AuthSwrProvider,
  ({ children }) => (
    <AdminTRPCProvider authProvider={AuthSwapProvider} basePath="/api/admin-ui/trpc">
      {children}
    </AdminTRPCProvider>
  ),
]);

/**
 * These providers will track some information about the current user.
 */
const TrackingProviders = createContainer([AnalyticsProvider, SentryUserProvider, FeatureFlagProvider]);

/**
 * The application can be wrapped in this component to give it access to all providers.
 */
export function AllProviders({ children }: Props): JSX.Element {
  return (
    <SSRBoundary>
      <Suspense fallback={<LogoSpinner />}>
        <BasicProviders>
          <IdentityProviders>
            <TRPCProviders>
              <TeamLibraryProviders>
                <TrackingProviders>{children}</TrackingProviders>
              </TeamLibraryProviders>
            </TRPCProviders>
          </IdentityProviders>
        </BasicProviders>
      </Suspense>
    </SSRBoundary>
  );
}
