import { useProvider } from '@newfront-insurance/react-provision';
import { LDProvider, useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import React, { useEffect } from 'react';

import { AuthProvider } from './auth';

import { getConfig } from '@/config';

const { LAUNCH_DARKLY } = getConfig();

interface Props {
  children: React.ReactNode;
}

/**
 * Initialises LaunchDarkly and automatically updates the current LaunchDarkly user whenever the auth state changes. We don't
 * set the user here and we just assume they're anonymous. We'll use the provider below to keep the LaunchDarkly user and the Newfront
 * user in sync.
 */
export function FeatureFlagProvider({ children }: Props): JSX.Element {
  return (
    <LDProvider
      clientSideID={LAUNCH_DARKLY.PROJECTS.CLIENT_DASH}
      options={{ bootstrap: 'localStorage' }}
      reactOptions={{ useCamelCaseFlagKeys: false }}
    >
      <FeatureFlagUserProvider>{children}</FeatureFlagUserProvider>
    </LDProvider>
  );
}

/**
 * Internal component that updates the user in the LaunchDarkly client whenever the auth state
 * changes. The feature flags will be reloaded whenever the user changes.
 */
function FeatureFlagUserProvider({ children }: Props): JSX.Element {
  const { userDetails } = useProvider(AuthProvider);
  const client = useLDClient();

  useEffect(() => {
    if (!client) return;

    if (userDetails) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      client.identify({
        email: userDetails.email,
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        key: userDetails.uuid,
        anonymous: false,
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      client.identify({ anonymous: true });
    }
  }, [userDetails, client]);

  return <>{children}</>;
}

export enum FeatureFlags {
  AUTOMATED_INVOICING = 'automated-invoicing-sending',
  CERTIFICATE_VERSIONS_FEATURE = 'certificate-versions-feature',
  EB_FILE_MANAGEMENT = 'eb-file-management',
  PLAN_DOCUMENT_DOWNLOAD = 'plan-document-download',
  MANAGE_TEAM_MEMBERS_FEATURE = 'manage-team-members-feature',
  EB_HOME_PAGE = 'eb-home-page',
  NEW_PASSWORD_FLOW_ACTIVE = 'new-password-flow-active',
  CENSUS_FILE_FEATURE = 'census-file',
  NEW_INVITE_MODAL = 'new-invite-modal',
}

/**
 * Load the value of a single feature flag, providing a fallback value. The key should match the
 * name of the flag in LaunchDarkly exactly if `useCamelCaseFlagKeys` is set to false, otherwise it
 * should be the camelcase version of the key.
 */
export function useFeatureFlag<T>(key: FeatureFlags, fallbackValue: T): T {
  const flags = useFlags();
  if (!(key in flags)) {
    return fallbackValue;
  }
  return flags[key];
}
